@import "../../../assets/scss/dark-mode-variabel";

body.dark-mode {
  .chat-header {
    background-color: $dark-01dp;
    transition: $transition-darkmode;
  }
}

.chat-header {
  background-color: #fff;
  transition: $transition-darkmode;
  box-shadow: 5px 1px 5px rgba(0,0,0,0.1);
}
