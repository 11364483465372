@import '../../assets/scss/dark-mode-variabel';

body.dark-mode {
  .contact-card {
    background-color: $dark-02dp !important;
    transition: $transition-darkmode;
    &.active {
      background-color: $dark-08dp !important;
    }
  }
}

.contact-card {
  transition: $transition-darkmode;
  border: 0 !important;
  background-color: #fafafa !important;
  box-shadow: 0 1px 10px rgba($color: #000000, $alpha: 0.1);
  border-radius: 14px !important;

  &.active {
    border-left: 5px solid #7e98df !important;
    background-color: rgb(241, 237, 237) !important;
  }

  .card-body {
    padding: 20px 10px !important;
  }

  .main-contact {
    display: flex;
    justify-content: space-between;

    .left-contact {
      display: flex;
      align-items: center;

      .contact-title {
        font-weight: 600;
        font-size: 17px !important;
      }

      .contact-description {
        font-size: 14px;
        margin-top: 3px;
        opacity: 0.6;
      }
    }

    .right-contact {
      display: flex;
      align-items: flex-end;
      flex-direction: column;
      .clock {
        font-size: 13px;
        opacity: 0.6;
        margin-bottom: 5px;
      }
    }
  }
}

.icon-bar-v {
  opacity: 0.6;
}
