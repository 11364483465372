@import "../../../assets/scss/dark-mode-variabel";

body.dark-mode {
  .main-menu {
    .nav-link {
      transition: $transition-darkmode;
      background-color: $dark-04dp !important;
      color: #f8f9fa;
      border: 2px solid $dark-04dp;
      &.active {
        border-bottom: 2px solid #555;
      }
    }
  }
}

.main-menu {
  .nav-link {
    transition: $transition-darkmode;
    background-color: #7e98df !important;
    border-radius: 0 !important;
    color: white;
    border: 2px solid #7e98df;
    &.active {
      border-bottom: 2px solid #555;
    }
  }
}

.main-menu-parent {
  position: sticky;
  top: 0;
  z-index: 1020;
}

@media screen and (max-width: 767px) {
  .main-menu-parent {
    position: fixed !important;
    bottom: 0 !important;
    top: auto;
    width: 100%;
    left: 0;
    .nav-link {
      padding: 14px 1rem !important;
    }
    .nav-item:nth-child(1) {
      .nav-link {
        border-radius: 20px 0 0 0 !important;
      }
    }
    .nav-item:nth-child(4) {
      .nav-link {
        border-radius: 0 20px 0 0 !important;
      }
    }
  }
}