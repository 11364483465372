  .story {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  
    &:focus {
      outline: none;
    }
    .border-new {
      border: 2px solid #7e98df;
    }
    .border-view {
      border: 2px solid #ddd;
    }

    .story-name {
      font-size: 12px;
    }
  
  }