#about-us {
  margin-top: 120px;
}

@media screen and (max-width: 767px) {
  #img-about {
    display: none;
  }
}

.about-title {
  font-size: 20px;
  display: inline-block;
  margin-bottom: 15px;
  border-bottom: 5px solid #ddd;
}

.about-sub-title {
  font-size: 40px;
  margin-bottom: 20px;
}

.about-description {
  opacity: 0.7;
  margin-bottom: 20px;
}

body.dark-mode {
  .about-sosmed-item {
    color: #fafafa;
  }
}

.about-social-media {
  display: flex;

  .about-sosmed-item {
    width: 50px;
    height: 50px;
    padding: 10px;
    border-radius: 50%;
    box-shadow: 0 11.7px 10px rgba(0, 0, 0, 0.008),
      0 51px 80px rgba(0, 0, 0, 0.02);
    border: 1px solid #ddd;
    text-align: center;
    font-size: 18px;
    opacity: 0.7;
    margin-right: 10px;
    cursor: pointer;
    text-decoration: none;
    color: #333;
    &:hover {
      opacity: 0.9;
    }
  }
}
