.img-avatar {

  .square {
    border-radius: 0;
  }
  
  img, span {
    object-fit: cover;
    display: inline-flex;
    object-position: center;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    font-size: inherit;
    font-weight: 400;
    line-height: 1;
    text-align: center;
    overflow: visible;
    position: relative;
    color: white;
    text-transform: uppercase;

    &.border-avatar {
      border: 2px solid #7e98df;
      padding: 2px;
    }
    &.square {
      border-radius: 0 !important;
    }
    &.rounded-avatar {
      border-radius: 8px !important;
    }
    &.rounded-avatar-sm {
      border-radius: 4px !important;
    }
    &.rounded-avatar-md {
      border-radius: 10px !important;
    }
    &.rounded-avatar-lg {
      border-radius: 12px !important;
    }
    &.rounded-avatar-top {
      border-radius: 6px 6px 0 0 !important;
    }
    &.rounded-avatar-left {
      border-radius: 6px 0 0 6px !important;
    }
    &.rounded-avatar-bottom {
      border-radius: 0 0 6px 6px !important;
    }
    &.rounded-avatar-right {
      border-radius: 0 6px 6px 0 !important;
    }

  }
}