.close-story {
  position: absolute;
  background-color: #343a40;
  color: white;
  padding: 5px 8px;
  left: 0;
  top: -1px;
  z-index: 2;
  border-radius: 3px 0 15px 0;
  cursor: pointer;
  font-size: 15px;
}

.trash-story {
  position: absolute;
  background-color: #d14339;
  color: white;
  padding: 5px 8px;
  right: 0;
  top: -1px;
  z-index: 2;
  border-radius: 3px 0 0 15px;
  cursor: pointer;
  font-size: 14px;
}

.total-story {
  position: absolute;
  right: 0;
  top: -8px;
}

.content-story {
  min-height: 60vh;
}