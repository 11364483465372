@import "../../../assets/scss/dark-mode-variabel";

body.dark-mode {
  .dropdown-menu {
    background: $dark-08dp !important;
  }
}

.drop-nav .btn:focus {
  outline: none !important;
  background-color: transparent !important;
  box-shadow: none !important;
}

.dropdown-menu.show {
  z-index: 2000;
}

.dropdown-menu {
  background: #7e98df !important;
  border-radius: 35px 10px 35px 35px !important;
}

.dropdown-item {
  color: white !important;
  margin-bottom: 10px;
}
.dropdown-item:hover {
  background-color: transparent !important;
  opacity: 0.8;
}
