@import '../../../assets/scss/dark-mode-variabel';

body.dark-mode {
  #join {
    background-color: $dark-01dp;

    .card-join {
      background-color: $dark-01dp;
      border: 1px solid #333;
    }
  }
}

#join {
  padding: 130px 0;
  background-color: #fff;

  .join-title {
    font-size: 36px;
    text-align: center;
    margin-bottom: 40px;
  }

  @media screen and (max-width: 600px) {
    .join-title {
      font-size: 30px;
    }
  }

  .card-join {
    cursor: pointer;
    background-color: #fff;
    border-radius: 18px;
    padding: 40px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .join-item-title {
      font-weight: bold;
      text-align: center;
      margin-bottom: 8px;
    }

    .join-desc {
      text-align: center;
      margin-bottom: 0;
    }

    .img-join {
      width: 200px;
      height: 200px;
    }

    &:hover {
      transition: all 0.2s ease-in-out;
      box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
      transform: scale(1.02);
    }
  }
  .join-btn {
    min-width: 300px;
    font-weight: bold;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 20px;
  }
}
