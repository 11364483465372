@import '../../../assets/scss/dark-mode-variabel';

body.dark-mode {
  .bg-detail-chat {
    background-color: #12121212;
    transition: $transition-darkmode;
  }
  .bottom-input {
    transition: $transition-darkmode;
    background-color: $dark-01dp;
  }
  .chat-scroll-to-bottom {
    background: $dark-04dp;
    color: #fff;
  }
}
.bg-detail-chat {
  background-color: #f8f9fa;
  transition: $transition-darkmode;
}
.main-chat {
  overflow: auto;
  height: calc(100vh - 163px);
}
.right-chat {
  display: flex;
  flex-direction: column;
}
.icon-sticker {
  right: 70px;
  bottom: 28px;
  width: 20px;
}
.icon-plus {
  right: 100px;
  bottom: 29px;
  width: 17px;
}
.icon-send {
  right: 40px;
  bottom: 29px;
  width: 17px;
}
.input-chat {
  border: 0;
  background: #ddd;
  border-radius: 15px;

  padding-right: 120px !important;
}
.chat-input-card {
  position: absolute;
  width: 100%;
  bottom: 0;
}

.bottom-input {
  position: fixed;
  bottom: 0;
  width: 66.5%;
  padding: 20px 10px;
  background-color: #fff;
  z-index: 1000;
}

.chat-scroll-to-bottom {
  position: fixed;
  bottom: 110px;
  right: 23px;
  background: #fff;
  height: 40px;
  width: 40px;
  align-items: center;
  display: flex;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  justify-content: center;
  border-radius: 50%;
  transition: 0.5s ease-in-out;
  cursor: pointer;
  &:hover {
    transform: rotate(360deg) !important;
  }
}

@media screen and (max-width: 992px) {
  .bottom-input {
    width: 58%;
  }
}

@media screen and (max-width: 767px) {
  .bottom-input {
    width: 100%;
  }
  .main-chat {
    padding-bottom: 60px;
  }
  .chat-scroll-to-bottom {
    bottom: 140px;
  }
}
