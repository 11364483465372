/*!
 * Bootstrap v4.5.3 (https://getbootstrap.com/)
 * Copyright 2011-2020 The Bootstrap Authors
 * Copyright 2011-2020 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap');
@import 'functions';
@import 'variables';
@import 'mixins';
@import 'root';
@import 'reboot';
@import 'type';
@import 'images';
@import 'code';
@import 'grid';
@import 'tables';
@import 'forms';
@import 'buttons';
@import 'transitions';
@import 'dropdown';
@import 'button-group';
@import 'input-group';
@import 'custom-forms';
@import 'nav';
@import 'navbar';
@import 'card';
@import 'breadcrumb';
@import 'pagination';
@import 'badge';
@import 'jumbotron';
@import 'alert';
@import 'progress';
@import 'media';
@import 'list-group';
@import 'close';
@import 'toasts';
@import 'modal';
@import 'tooltip';
@import 'popover';
@import 'carousel';
@import 'spinners';
@import 'utilities';
@import 'print';
@import 'dark-mode-variabel';

.color-lb {
  color: #7e98df !important;
}

.bg-lb {
  background-color: #7e98df !important;
}

::-webkit-scrollbar {
  width: 1px;
  height: 1px;
}

body.dark-mode {
  ::-webkit-scrollbar-track {
    transition: $transition-darkmode;
    background: #222;
  }
}

.img-auth {
  padding: 0 100px !important;
}

@media screen and (max-width: 900px) {
  .img-auth {
    padding: 0 50px !important;
  }
}

.pointer-disabled {
  cursor: not-allowed !important;
}

/* Track */
::-webkit-scrollbar-track {
  transition: $transition-darkmode;
  background: #fff;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #7e98df;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #7e98df;
}

.max-vh-100 {
  max-height: 100vh;
}

body.dark-mode {
  .left-content-home {
    border-right: 1px solid #333;
    transition: $transition-darkmode;
  }
}

.left-content-home {
  border-right: 1px solid #ddd;
  transition: $transition-darkmode;
}

@media screen and (max-width: 767px) {
  .content-message {
    display: none !important;
  }
}
@media screen and (max-width: 766px) {
  .mobile {
    display: none !important;
  }
}

.no-focus:focus {
  outline: none !important;
  background-color: transparent !important;
}

.cursor-pointer {
  cursor: pointer !important;
}
.font-10 {
  font-size: 10px !important;
}
.font-11 {
  font-size: 11px !important;
}
.font-12 {
  font-size: 12px !important;
}
.font-13 {
  font-size: 13px !important;
}
.font-14 {
  font-size: 14px !important;
}
.font-15 {
  font-size: 15px !important;
}
.font-16 {
  font-size: 16px !important;
}
.font-17 {
  font-size: 17px !important;
}
.font-18 {
  font-size: 18px !important;
}
.font-19 {
  font-size: 19px !important;
}
.font-20 {
  font-size: 20px !important;
}
.font-21 {
  font-size: 21px !important;
}
.font-22 {
  font-size: 22px !important;
}
.font-23 {
  font-size: 23px !important;
}
.font-24 {
  font-size: 24px !important;
}
.font-25 {
  font-size: 25px !important;
}
.weight-100 {
  font-weight: 100 !important;
}
.weight-200 {
  font-weight: 200 !important;
}
.weight-300 {
  font-weight: 300 !important;
}
.weight-300 {
  font-weight: 300 !important;
}
.weight-400 {
  font-weight: 400 !important;
}
.weight-500 {
  font-weight: 500 !important;
}
.weight-600 {
  font-weight: 600 !important;
}
.weight-700 {
  font-weight: 700 !important;
}
.weight-800 {
  font-weight: 800 !important;
}
.weight-900 {
  font-weight: 900 !important;
}

label {
  opacity: 0.8;
}

body.dark-mode {
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #fff;
    background-color: $dark-08dp;
    border-color: $dark-08dp $dark-08dp #dee2e6;
    transition: $transition-darkmode;
  }
  .dark-mode-toggle {
    background-color: #222;
  }
}

.nav-tabs a.nav-link {
  transition: $transition-darkmode;
  color: #777;
}

.wrapper-progress {
  .progress {
    height: 2px !important;
  }
}

.modal-secondbc {
  background-color: rgba(0, 0, 0, 0.6) !important;
}

.dark-mode-toggle {
  transition: 0.5s ease-in-out;
  background-color: #fff;
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  border-radius: 50%;
  height: 60px;
  width: 60px;
  position: fixed;
  bottom: 20px;
  right: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.darkmode-v2 {
  outline: none;
  box-shadow: none;
}

.darkmode-v2:focus {
  outline: none;
  box-shadow: none;
}

@media screen and (max-width: 767px) {
  .dashboard-mobile {
    margin-bottom: 80px;
  }
}
