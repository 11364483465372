@import '../../../../assets/scss/dark-mode-variabel';

body.dark-mode {
  .feature-icon {
    background-color: $dark-04dp;
  }
  .feature-card {
    background-color: $dark-02dp;
  }
}

.feature-icon {
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 20px;
  padding: 10px;
  margin-right: 10px;
  box-shadow: 0 3.9px 5.3px rgba(0, 0, 0, 0.017),
    0 13px 17.9px rgba(0, 0, 0, 0.025), 0 58px 80px rgba(0, 0, 0, 0.05);
}

.feature-card {
  box-shadow: 0 11.7px 10px rgba(0, 0, 0, 0.008),
    0 51px 80px rgba(0, 0, 0, 0.02);

  border: 0 !important;
  border-radius: 20px !important;
  cursor: pointer;
  &:hover {
    box-shadow: none;
  }
}

.feature-title {
  margin-bottom: 0;
}

.feature-desc {
  opacity: 0.8;
  font-weight: 400;
}
