@import '../../../assets/scss/dark-mode-variabel';

body.dark-mode {
  .navbar-bg {
    &.active {
      background-color: $dark-04dp;
    }
  }
  .nav-lists .nav-item {
    color: #fff;
  }

  .brand-text {
    color: #fff;
  }
  .btn-log {
    color: #fff;
  }
  .menu-close {
    color: #fff;
  }

  .navbar-mobile {
    background-color: $dark-03dp;
  }
  .mobile-nav-item {
    color: #fff;
  }
  
}

.navbar-bg {
  margin-top: -80px;
  background-color: transparent;

  &.active {
    background-color: #fff;
  }
}

.nav-lists {
  display: flex;

  .nav-item {
    cursor: pointer;
    margin-right: 18px;
    text-decoration: none;
    color: #111;
    opacity: 0.6;
    &.active {
      opacity: 1;
    }
  }
}

.menu-close {
  display: none !important;
  z-index: 100;
}

.navbar-brand span {
  font-weight: bold;
  font-size: 16px;
  transition: 0.3s ease-in-out;
  position: relative;
}

.brand-text {
  color: #333;
  position: absolute;
  top: -100px;
  &.active {
    top: 0;
  }
}

@media screen and (max-width: 700px) {
  .nav-lists {
    display: none;
  }
  .btn-wrapper {
    display: none;
  }
  .menu-close {
    display: block !important;
  }
}

.navbar-mobile {
  transition: 0.2s ease-in-out !important;
  padding: 20px;
  position: fixed;
  height: 100%;
  background-color: #ddd;
  left: 0;
  bottom: 0;
  top: -100%;
  right: 0;
  width: 100%;
  &.active {
    transition: 0.2s ease-in-out !important;
    width: 100%;
    display: block;
    top: 0;
  }
}

.mobile-nav-item {
  font-size: 24px;
  color: #222;
  text-decoration: none;
  margin-bottom: 20px;
  cursor: pointer;
  opacity: 0.7;

  &.active {
    opacity: 1;
  }
  &:hover {
    text-decoration: none;
    opacity: 0.9;
  }
}