@import '../../../assets/scss/dark-mode-variabel';

body.dark-mode {
  #hero-section {
    background: $dark-02dp;
  }
}

#hero-section {
  padding-top: 100px;
  background: #e8ffff;
  border-radius: 0 0 360px 360px;
}

.hero-section {
  // height: 600px;
  padding: 40px 0 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .hero-title {
    text-align: center;
    font-weight: bold;
    font-size: 40px;
  }

  @media screen and (max-width: 600px) {
    .hero-title {
      font-size: 32px;
    }
  }

  .hero-desc {
    // max-width: 600px;
    text-align: center;
    margin-bottom: 10px;
    margin-top: 20px;
  }

  .hero-img {
    width: 900px;
    margin-bottom: -60px;
  }

  .btn-hero {
    min-width: 200px;
    min-height: 45px;
  }

  @media screen and (max-width: 488px) {
    .btn-hero {
      min-width: auto;
      min-height: auto;
    }
  }
}
