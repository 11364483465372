@import '../../../assets/scss/dark-mode-variabel';

body.dark-mode {
  .message-text {
    background-color: $dark-08dp;
    color: #feffff;
    transition: $transition-darkmode;
  }
  .message-text.map {
    background-color: $dark-08dp;
    color: #feffff;
    transition: $transition-darkmode;
  }
  .receiver {
    .set {
      .message-text {
        transition: $transition-darkmode;
        background-color: $dark-24dp !important;
        color: #feffff;
      }
    }
  }
  .sender {
    .chat-name {
      transition: $transition-darkmode;
      color: white;
    }
    .giphy-link {
      color: #fff;
    }
  }
}

.deleted-message {
  font-weight: 600;
  font-size: 14px;
}

.chat-card {
  display: flex;
  margin-bottom: 25px;
  align-items: flex-end;
  &:last-child {
    margin-top: 30px;
  }
  .set {
    display: flex;
    align-items: flex-end;
    .contact-image {
      margin-left: 10px;
    }
    .clock {
      float: right;
    }
  }
  .chat-name {
    font-size: 13px;
    display: inline-block;
    margin-bottom: 8px;
  }
  &.sender {
    justify-content: flex-end;
    .set {
      flex-direction: row-reverse;
    }
    .chat-name {
      transition: $transition-darkmode;
      color: black;
    }
  }

  &.receiver {
    display: flex;
    align-items: flex-start !important;
    margin-bottom: 25px;
    .giphy-link {
      color: #fff;
    }
    .set {
      flex-direction: row;
      .contact-image {
        margin-right: 10px;
      }
      .clock {
        float: right;
      }
      .message-text {
        transition: $transition-darkmode;
        border-radius: 25px 25px 25px 0;
        background-color: #7e98df !important;
        color: white;
      }
    }
    .chat-name {
      color: white;
    }
  }

  .message-text {
    transition: $transition-darkmode;
    border-radius: 25px 25px 0 25px;
    background-color: #eef;
    color: #444;
    max-width: 350px;
    min-width: 200px;
    padding: 15px;
    font-size: 14px;
  }

  .message-text.map {
    transition: $transition-darkmode;
    border-radius: 25px 25px 0 25px;
    background-color: #eef;
    color: #444;
    max-width: 350px;
    min-width: 300px;
    padding: 15px;
    font-size: 14px;
  }

  .message-img {
    width: 100%;
    object-fit: cover;
    display: inline-flex;
    object-position: center;
    max-height: 250px;
    border-radius: 5px;
    cursor: pointer;
  }
}
// .main-chat {
//   overflow: auto;
//   margin-bottom: 70px;
// }
// .right-chat {
//   display: flex;
//   flex-direction: column;
// }

@media screen and (max-width: 550px) {
  .contact-image {
    display: none !important;
  }
}
