@import '../../../assets/scss/dark-mode-variabel';

body.dark-mode {
  .sidebar-right {
    background-color: $dark-01dp !important;
    transition: 0.6s cubic-bezier(1, 0, 0, 1);
    .border-side-right {
      border-bottom: 1px solid $dark-24dp;
    }
  }
}

.sidebar-right {
  overflow: auto;
  background-color: #f8f9fa !important;
  box-shadow: 1px 1px 10px rgba($color: #000000, $alpha: 0.1);
  height: 100vh;
  padding: 20px;
  width: 400px;
  position: fixed;
  z-index: 1010;
  top: 0;
  right: -100%;
  transition: 0.6s cubic-bezier(1, 0, 0, 1);
  &.active {
    right: 0 !important;
    transition: 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .border-side-right {
    border-bottom: 1px solid #ddd;
  }
  .header-side-right {
    display: flex;
    justify-content: flex-end;
    position: sticky;
    top: 0;
    span {
      cursor: pointer;
      opacity: 0.8;
    }
  }
  .main-side-right {
    margin: 20px 0;
    .name-or-group {
      text-align: center;
      font-size: 19px;
      color: #7e98df;
      margin-bottom: 18px;
    }
  }
}

@media screen and(max-width: 480px) {
  .sidebar-right {
    width: 100%;
  }
}
