@import '../../../assets/scss/dark-mode-variabel';

body.dark-mode {
  .sticker-wrapper {
    background-color: $dark-06dp;
  }
}

.chat-input-icon {
  position: relative;
  span {
    cursor: pointer;
  }
  .icon-plus {
    opacity: 0.6;
    right: 100px;
    top: 0;
    position: absolute;
    flex-direction: column;
    justify-content: center;
    display: flex;
    align-items: center;
    width: 30px;
    height: 45px;
  }
  .icon-sticker {
    opacity: 0.6;
    right: 70px;
    top: 0;
    position: absolute;
    flex-direction: column;
    justify-content: center;
    display: flex;
    align-items: center;
    width: 30px;
    height: 45px;
  }
  .icon-emoji {
    opacity: 0.6;
    right: 40px;
    top: 0;
    position: absolute;
    flex-direction: column;
    justify-content: center;
    display: flex;
    align-items: center;
    width: 30px;
    height: 45px;
  }
  .icon-send {
    opacity: 0.6;
    right: 10px;
    top: 0;
    position: absolute;
    flex-direction: column;
    justify-content: center;
    display: flex;
    align-items: center;
    width: 30px;
    height: 45px;
  }
}

.emoji-wrapper {
  position: absolute;
  bottom: 75px;
  z-index: 1000;
  right: 15px;
}

.chat-input {
  resize: none;
  padding: 10px 140px 10px 15px !important;
}

.sticker-wrapper {
  background-color: #fff;
  width: 400px;
  padding: 20px;
  position: absolute;
  bottom: 75px;
  right: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 20px 20px 0 0;
  .sticker-list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    height: 200px;
    overflow-y: scroll;
  }
  .sticker-item {
    margin: 5px;
    width: 60px;
    height: 60px;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
  }
}

@media screen and (max-width: 767px) {
  .chat-input {
    padding: 20px 140px 20px 15px !important;
    border-radius: 30px !important;
    overflow: hidden !important;
  }
  .icon-plus {
    height: 63px !important;
    font-size: 20px;
  }
  .icon-emoji {
    height: 63px !important;
    font-size: 20px;
  }
  .icon-send {
    height: 63px !important;
    font-size: 20px;
  }
  .icon-sticker {
    height: 63px !important;
    font-size: 20px;
  }
  .emoji-wrapper {
    bottom: 95px !important;
  }
  .sticker-wrapper {
    bottom: 95px !important;
  }
}
