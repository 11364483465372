$dark-00dp : #121212 !default;
$dark-01dp : #1E1E1E !default;
$dark-02dp : #232323 !default;
$dark-03dp : #252525 !default;
$dark-04dp : #272727 !default;
$dark-06dp : #2C2C2C !default;
$dark-08dp : #2E2E2E !default;
$dark-12dp : #333333 !default;
$dark-16dp : #363636 !default;
$dark-24dp : #383838 !default;
$transition-darkmode: 0s;