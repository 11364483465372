@import "../../../assets/scss/dark-mode-variabel";

body.dark-mode {
  .empty-chat {
    background-color: $dark-04dp !important;
    transition: $transition-darkmode;
  }
  .no-message p {
    color: #f8f9fa;
  }
}

.empty-chat {
  background-color: #f8f9fa !important;
  transition: $transition-darkmode;
}

.no-message {
  display: flex;
  justify-content: center;
  align-items: center;
}

.no-message p {
  color: #848484;
  font-size: 18px;
}