@import '../../../assets/scss/dark-mode-variabel';

body.dark-mode {
  .footer {
    background-color: $dark-02dp;
  }
}

.footer {
  background-color: #e8ffff;

  .main-footer {
    display: flex;
    justify-content: space-between;
  }
  .menu-footer {
    display: flex;
  }
  @media screen and (max-width: 767px) {
    .main-footer {
      flex-direction: column-reverse;
      text-align: center !important;
      justify-content: center !important;
    }
    .menu-footer {
      flex-wrap: wrap;
      justify-content: center;
      margin-bottom: 10px;
    }
  }
}

#about-us {
  border-radius: 260px 260px 0 0;
}