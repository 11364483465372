@import '../../assets/scss/dark-mode-variabel';

body.dark-mode {
  .card-auth {
    background-color: transparent;
    box-shadow: none !important;
  }
}

.bg-auth {
  position: absolute;
  width: 100vw;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

#particles-js {
  height: 100%;
}

.card-auth {
  padding: 20px 10px;
}

.auth-input {
  border-radius: 5px !important;
  padding: 12px 40px 12px 40px !important;
  height: auto !important;
  border: 1px solid rgba($color: #999, $alpha: 0.1) !important;
}

.btn-auth {
  padding: 8px 10px !important;
}

.icon-auth-left {
  position: absolute;
  top: 12px;
  left: 15px;
  color: #999;
}

.icon-auth-right {
  position: absolute;
  top: 12px;
  right: 15px;
  color: #999;
  cursor: pointer;
}

.auth-title {
  opacity: 0.8;
  margin-bottom: 12px;
}

.auth-desc {
  opacity: 0.8;
}

.or-auth {
  font-size: 14px !important;
}
