@import "../../../assets/scss/dark-mode-variabel";

body.dark-mode {
  .setting-item {
    border-bottom: 1px solid $dark-08dp !important;
  }
}

.username-title {
  margin-bottom: 0;
  color: #7e98df !important;
  font-size: 18px;
  text-align: center;
}

.name-title {
  margin-bottom: 0 !important;
  font-size: 19px;
  margin-top: 15px;
}

.title-account {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 14px;
}

.setting-item {
  border-bottom: 1px solid #ddd;
  .sub-title {
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 6px;
    opacity: 0.9;
  }
  .desc-title {
    font-size: 14px;
    opacity: 0.5;
    margin-bottom: 0;
    padding-bottom: 12px;
  }
}
